import React, { useState } from 'react';
import Typed from 'react-typed';
import SEO from "../common/SEO";
import Logo from "../elements/logo/Logo";
import MyVerticallyCenteredModal from '../elements/modal/Modal'

const ComingSoon = () => {
    const [modalShow, setModalShow] = useState(false);

    return (
        <>
            <SEO title='"EDESSA" LLC' />
            <main className="page-wrapper">
                <div className="slider-area slider-style-1 bg-transparent height-850">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                   <h1>
                                        <Logo
                                            image={`${process.env.PUBLIC_URL}/images/logo/logos1.png`}
                                        />
                                    </h1>
                                    <h1>  
                                        <Typed
                                            strings={["COMING SOON"]}
                                            typeSpeed={80}
                                            backSpeed={5}
                                            backDelay={1000}
                                            loop
                                        />
                                    </h1>
                                    <p className="text"></p>
                                    <div className="button-group">
                                        <button className="btn-default btn-medium btn-border round btn-icon"
                                             onClick={() => setModalShow(true)}>
                                            Contact Us
                                         </button>
                                        <MyVerticallyCenteredModal
                                            show={modalShow}
                                            onHide={() => setModalShow(false)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>           
                    </div>
                </div>
             </main>  
         </>      
     )       
 }       
    
export default ComingSoon;

