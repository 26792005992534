import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function MyVerticallyCenteredModal(props) {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Body>
                <h5>Phone: +374 10 531240</h5>
                <h5>Email: info@edessa.am</h5>
            </Modal.Body>
        </Modal>
    );
}
export default MyVerticallyCenteredModal